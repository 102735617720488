import './App.css';

import axios from 'axios';

import TaskListView from './components/TaskListView';
import {
  useEffect, useRef, useState,
  //  useState 
} from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { FaClipboardList } from 'react-icons/fa';



function App() {

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
  const API_KEY = process.env.REACT_APP_API_KEY
  // const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest']
  const SCOPES = 'https://www.googleapis.com/auth/tasks.readonly https://www.googleapis.com/auth/tasks'

  //  const [tokenClient ,setTokenClient] = useState("")
  const [taskLists, setTaskLists] = useState([])
  const [tasks, setTasks] = useState([])

  const [showPopup, setShowPopup] = useState(false)
  const [currentTaskList, setCurrentTaskList] = useState(JSON.parse(localStorage.getItem("current-task-list")) || '')

  const tokenClient = useRef(null);
  const oauth2Token = useRef(null);

  const [completedTasks, setCompletedTasks] = useState(0)
  const [showCompletedTasks, setShowCompletedTasks] = useState(false)

  const [isSingedIn, setIsSignedIn] = useState(false)
  const [sessionEnded, setSessionEnded] = useState(false)
  // const [token, setToken] = useState(null)


  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem('token'))
    if (temp === null) {
      setIsSignedIn(false)
      setShowPopup(true)
    } else {
      setIsSignedIn(true)
    }
  }, [])


  useEffect(() => {
    initAuthToken()
  }, [])

  const initAuthToken = () => {
    const script_gsi = document.createElement("script");
    script_gsi.src = "https://accounts.google.com/gsi/client";

    script_gsi.onload = () => {
      tokenClient.current = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: (tokenResponse) => {
          // console.log(" window.google.accounts.oauth2::",tokenResponse)
          oauth2Token.current = tokenResponse.access_token
          localStorage.setItem("token", JSON.stringify(tokenResponse.access_token))
          setIsSignedIn(true)
          setSessionEnded(false)
          // console.log("oauth2Token.current:",oauth2Token.current)
          window.location.reload()

        },

      });
    };
    document.body.appendChild(script_gsi);
  }


  async function getTaskLists() {
    let o2Token = JSON.parse(localStorage.getItem("token"))
    if (o2Token !== null) {
      console.log("o2Token(localStorage):", o2Token)
      let endpoint = `https://tasks.googleapis.com/tasks/v1/users/@me/lists?key=${API_KEY}`;
      axios.get(endpoint, { headers: { Authorization: `Bearer ${o2Token}` } }).then((res) => {
        setTaskLists(res.data.items)
        // console.log("(TaskLists)RES:", res)
      }).catch((err) => {
        if (err.response.status === 401) {
          console.log("Get Failed:", err)
          setIsSignedIn(false)
          setSessionEnded(true)
          setShowPopup(true)
        }else {
          console.log("Something went wrong: Getting TaskLists", err)
        }
      })

    } else {
      console.log("No token")
      setIsSignedIn(false)
    }
  }

  async function getTasks(task_list_id) {
    let o2Token = JSON.parse(localStorage.getItem("token"))
    if (o2Token !== null) {
      let endpoint = `https://tasks.googleapis.com/tasks/v1/lists/${task_list_id}/tasks?key=${API_KEY}`;
      axios.get(endpoint, { headers: { Authorization: `Bearer ${o2Token}` } }).then((res) => {
        let list = res.data.items
        setTasks(list)
        calculateCompletedTasks(list)
        // console.log("(Tasks)RES:", res)
      }).catch((err) => {
        if (err?.response?.status === 401) {
          console.log("Get Failed:", err)
          setIsSignedIn(false) // TODO: replace this with access token fail state tracker 
          setSessionEnded(true)
          setShowPopup(true)

        } else {
          console.log("Something went wrong: Getting Tasks", err)
        }
      })

    } else {
      console.log("No token")
      setIsSignedIn(false)
    }
  }

  async function updateTask(_task_object) {
    let o2Token = JSON.parse(localStorage.getItem("token"))
    if (o2Token !== null) {
      console.log("Sending:", _task_object)
      let endpoint = `https://tasks.googleapis.com/tasks/v1/lists/${currentTaskList.id}/tasks/${_task_object.id}?key=${API_KEY}`;
      axios.put(endpoint, _task_object,{ headers: { Authorization: `Bearer ${o2Token}` } }).then((res) => {
        console.log("(Update)RES:", res)
        getTasks(currentTaskList.id)
      }).catch((err) => {
        if (err?.response?.status === 401) {
          console.log("Get Failed:", err)
          setIsSignedIn(false) // TODO: replace this with access token fail state tracker 
          setSessionEnded(true)
          setShowPopup(true)

        } else {
          alert("Something went wrong: Updating Tasks:\n"+err)
          console.log("Something went wrong: Updating Tasks", err)
        }
      })

    } else {
      console.log("No token")
      setIsSignedIn(false)
    }
  }

  const onCheckTask = (_task_object) =>{
      _task_object.status = "completed"  
      updateTask(_task_object)
  }
  const onUnCheckTask = (_task_object) =>{
    _task_object.status = "needsAction"  
    updateTask(_task_object)
}

  const selectCurrentTaskList = (task_list) => {
    setCurrentTaskList(task_list)
    getTasks(task_list.id)
    localStorage.setItem("current-task-list", JSON.stringify(task_list))
  }

  const calculateCompletedTasks = (list) =>{
    let total = 0
    // console.log("list:", l)
    for(let i =0; i < list.length; i++){
     if(list[i].status === "completed"){
      total++;
     }
    }
    console.log("total:", total)
    setCompletedTasks(total)
  }
  /**
 *  Sign in the user upon button click.
 */
  function handleAuthClick() {
    // tokenClient.current.callback = async (resp) => {
    //   console.log("Here")
    //   if (resp.error !== undefined) {
    //     throw (resp);
    //   }
    // };
    const _token = JSON.parse(localStorage.getItem("token"));

    if (_token == null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      console.log("Opening prompt")
      tokenClient.current.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      console.log("No prompt needed")
      tokenClient.current.requestAccessToken();  // Renew access token
    }
    console.log("tokenClient (At Auth):", tokenClient)
  }

  /**
*  Sign out the user upon button click.
*/

  function handleSignoutClick() {
    const _token = JSON.parse(localStorage.getItem("token"));
    if (_token !== null) {
      window.google.accounts.oauth2.revoke(_token.access_token);
      localStorage.setItem("token", null)
    }
    setIsSignedIn(false)
    setShowPopup(true)
  }

  useEffect(() => {
    // getTaskLists()
    // getTasks instead
    if (currentTaskList) {
      getTasks(currentTaskList.id)
    }
  }, [currentTaskList])

  return (
    <div className="App">
      {isSingedIn && (<button id="signout_button" onClick={() => handleSignoutClick()}>Sign Out</button>)}


      {/* <button id="s_button" onClick={() => getTaskLists()}>Get TaskLists</button> */}

      <div className='task-list-header'>
        <div className='task-list-header-title-container'
          onClick={() => {
            getTaskLists();
            setShowPopup(true)
          }}
        >
          <span id="task-list-header-title">{currentTaskList.title || "Select task list"}</span>
          <div id="arrow-down"><IoIosArrowDown  size={20}/></div>
        </div>
      </div>

      {
        showPopup && (
          <div id="backdrop" onClick={() => {
            if (isSingedIn) {
              setShowPopup(false)
            }
          }
          }>
            <div className='task-list-popup' >
              <div className='task-list-popup-header'>
                {isSingedIn && (<div className='task-list-popup-header-title'>Task lists</div>)}
                {!isSingedIn && !sessionEnded && (<div className='task-list-popup-header-title'>Login</div>)}
                {!isSingedIn && sessionEnded && (<div className='task-list-popup-header-title'>Login to renew session!!</div>)}
                {/* <MdOutlineCancel /> */}
              </div>
              {isSingedIn && (
                <div className='task-list-popup-content' >
                  {
                    Array.isArray(taskLists) ? taskLists?.map((item, i) => {
                      return (
                          <div key={item.id} id='task-list-item' onClick={() => selectCurrentTaskList(item)}>
                            <div><FaClipboardList /></div><div id='task-list-item-title'  >{item.title}</div>
                          </div>

                      )
                    }) : ''
                  }
                </div>
              )}
              {
                !isSingedIn && (
                  <div className='auth-popup-content'>
                    <button id="authorize_button" onClick={() => handleAuthClick()}>Sign in</button>
                  </div>
                )
              }

            </div>
          </div>
        )
      }
      <div className='completed-tracker'>
        <div>
          ({completedTasks}) Completed
        </div>
        <div>
          {
           <button id="show-tasks" onClick={() => setShowCompletedTasks(!showCompletedTasks)}> {showCompletedTasks?"Hide":"Show" } tasks</button>
          }
        </div>
      </div>


      <TaskListView tasks={tasks} checkTask={onCheckTask}  unCheckTask = {onUnCheckTask}  showCompletedTasks={showCompletedTasks} />
    </div>
  );
}

export default App;
