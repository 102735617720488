import {
  useEffect
  //  useState 
} from 'react';
import '../App.css';

import { VscDebugBreakpointLog } from 'react-icons/vsc';
import { RiCheckboxBlankLine } from 'react-icons/ri';
import { TbSquareCheckFilled } from 'react-icons/tb';



function TaskListView({ tasks, checkTask, unCheckTask,showCompletedTasks }) {

  useEffect(() => {
    //    console.log("Reloaded:")
    // console.log("Tasks:", tasks)

  }, [])



  return (
    <div className="TaskListView">
      <div id="task-container">
        {
          showCompletedTasks && (
            Array.isArray(tasks) ? tasks?.filter((task)=>  task.status === "completed" ).map((item, i) => {
        
              return (
                  
                  <div key={item.id} id='task-item'>
                    <div><VscDebugBreakpointLog /> </div>
                    <div  id='task-item-title'> {item.title}</div>
                    {
                      item.status === "completed" ? <>
                        <div key={i+"uncheck"} id="task-item-check" onClick={() => { 
                            console.log("Click:", item)
                            unCheckTask(item) }}>
                            <TbSquareCheckFilled size={30} />
                          </div>
                      </> :
                        <>
                      <div key={i+"check"} id="task-item-check" onClick={() => { checkTask(item) }}>
                          <RiCheckboxBlankLine size={30} />
  
                        </div>
                        </>
                    }
  
                  </div>
              )
            }) : ''
          )
        }

        {
          !showCompletedTasks&&(
            Array.isArray(tasks) ? tasks?.filter((task)=>  task.status !== "completed" ).map((item, i) => {
        
              return (
                  
                  <div key={item.id} id='task-item'>
                    <div><VscDebugBreakpointLog /> </div>
                    <div  id='task-item-title'> {item.title}</div>
                    {
                      item.status === "completed" ? <>
                        <div key={i+"uncheck"} id="task-item-check" onClick={() => { 
                            console.log("Click:", item)
                            unCheckTask(item) }}>
                            <TbSquareCheckFilled size={30} />
                          </div>
                      </> :
                        <>
                      <div key={i+"check"} id="task-item-check" onClick={() => { checkTask(item) }}>
                          <RiCheckboxBlankLine size={30} />
  
                        </div>
                        </>
                    }
  
                  </div>
              )
            }) : ''
          )
          
        }
      </div>

    </div>
  );
}

export default TaskListView;